import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { Types } from '../../../domain/types.domain';
import { AppHandler } from '../../app.handler';

import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { WebPkiAdapterInstruction } from '../../../builder/instructions/adapters/web-kpi.adapter.instruction';
import { CertificateRepositoryInstruction } from '../../../builder/instructions/repositories/certificate.repository.instructions';
import { ValidateCertificateAuthenticationServiceInstruction } from '../../../builder/instructions/services/validate-certificate-authentication.service.instruction';
import { ValidateCertificateAuthenticationOutput } from '../../../domain/certificate/dtos/validate-certificate-authentication.output';

import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { IconComponent } from '../../components/icon/icon.component';

@Component({
  selector: 'app-login-by-icp',
  standalone: true,
  imports: [AsyncPipe, RouterOutlet, LoadingSpinnerComponent, IconComponent],
  providers: [
    new AuthRepositoryInstruction(),
    new CertificateRepositoryInstruction(),
    new WebPkiAdapterInstruction(),
    new ValidateCertificateAuthenticationServiceInstruction(),
  ],
  templateUrl: './login-by-icp.component.html',
  styleUrls: ['./login-by-icp.component.scss'],
})
export class LoginByIcpComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);

  private readonly _settings = inject(Types.Settings);
  private readonly _webPkiAdapter = inject(Types.WebPkiAdapter);
  private readonly _validateCertificateService = inject(
    Types.ValidateCertificateAuthenticationService
  );

  public cityLogo: string | undefined = undefined;
  public cityName: string = '';
  public cityPhone: string = '';
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];
  public cityLoginUrl: string = '';

  public routeState = this._router.getCurrentNavigation()?.extras?.state;

  public validatingCertificate = signal(false);
  public certificates = signal<
    Array<{
      title: string;
      subtitle: string;
      type: string;
      data: any;
      disabled?: boolean;
    }>
  >([]);
  public allowJustICP = signal(false);
  public redirectUri = signal('');

  ngOnInit(): void {
    this._appHandler.setBackButtonHome(false);

    this._route.data.subscribe({
      next: ({ city }) => {
        this.cityIdentity = city.identidade;
        this.cityConnection = city.connection;
        this.cityLoginUrl = city.loginUrl;

        if (this.routeState) {
          this.allowJustICP.set(this.routeState['allowJustICP']);
        }

        this.init();
      },
    });
  }

  redirectToLogin(): void {
    if (this.cityLoginUrl) {
      window.location.href = this.cityLoginUrl;
      return;
    }

    this._router.navigate([`/${this.cityIdentity}`]);
  }

  async init() {
    this._appHandler.clearError();
    const hasPlugin = await this._webPkiAdapter.isInitialized();

    if (!hasPlugin.sucess) {
      this._appHandler.setLoading(false);

      if (hasPlugin.error === 'user_cancelled') {
        this._appHandler.setError(true, {
          message: 'Operação cancelada',
          description:
            'Você cancelou a operação de autenticação. É preciso autorizar o acesso aos seus certificados. Por favor, tente novamente.',
          actionText: 'Tentar novamente',
          action: () => {
            this.init();
          },
        });
        return;
      }

      if (hasPlugin.error === 'not_installed') {
        this._appHandler.setError(true, {
          message: 'Parece que você não possui a extensão',
          description:
            'Identificamos que você ainda não possui a extensão Web PKI instalada em seu navegador. Clique abaixo para instalar e prosseguir com a operação.',
          actionText: 'Saiba mais',
          action: () => {
            open(this._settings.webpki.pluginUrl, '_blank');
          },
        });
        return;
      }

      this._appHandler.setError(true, {
        message: 'A operação não pôde ser concluída.',
        description:
          'Se o problema persistir, entre em contato com nossa equipe de suporte.',
        actionText: 'Tentar novamente',
        action: () => {
          this.init();
        },
      });

      return;
    }

    const certificates = await this._webPkiAdapter.getICPCertificates();

    if (!certificates.length) {
      this._appHandler.setLoading(false);
      this._appHandler.setError(true, {
        message: 'Parece que você ainda não possui conta.',
        description:
          'Observamos que o CPF do certificado que você está tentando usar não está vinculado a nenhuma conta na Aprova Digital. Por favor, crie uma conta antes de tentar novamente.',
        actionText: 'Cria conta',
        action: () => {
          this._router.navigate([`/${this.cityIdentity}/create-account`], {
            state: {
              initialAccountData: {
                userMetadata: {
                  cpf: certificates[0].pkiBrazil?.cpf,
                },
              },
              blockedFields: ['cpf'],
            },
          });
        },
      });
      return;
    }

    this.certificates.set(
      certificates.map((cert) => this.mapToCertificateItem(cert))
    );

    this._appHandler.setLoading(false);
  }

  selectCertificate(certificate: any): void {
    this._appHandler.setLoading(true, {
      title: 'Carregando...',
      description: 'Estamos verificando as informações do seu certificado.',
    });

    Promise.all([
      this._webPkiAdapter.readCertificate(certificate.data),
      this._validateCertificateService.handle({
        cityIdentity: this.cityIdentity,
      }),
    ])
      .then(
        ([certificateBase64, certificateAuth]: [
          string | null,
          ValidateCertificateAuthenticationOutput
        ]) => {
          this.authenticate(certificate, certificateBase64, certificateAuth);
        }
      )
      .catch(() => {
        this._appHandler.setError(true, {
          message: 'A operação não pôde ser concluída.',
          description:
            'Não foi possível validar o certificado. Se o problema persistir, entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this.init();
          },
        });
        this._appHandler.setLoading(false);
      });
  }

  finishLogin(): void {
    window.location.href = this.redirectUri();
  }

  private mapToCertificateItem(certificate: any): {
    title: string;
    subtitle: string;
    type: string;
    data: any;
    disabled?: boolean;
  } {
    const isICP =
      certificate.issuerDN
        ?.split('O=')[1]
        ?.split(',')[0]
        ?.toLocaleLowerCase() === 'icp-brasil';
    const expiresAt = new Date(certificate.validityEnd);
    const cpfMasked = certificate.pkiBrazil?.cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4'
    );
    const acAdditionalText =
      this.allowJustICP() && !isICP ? ' - AC inválida' : '';

    return {
      title: certificate.subjectName,
      subtitle: `${cpfMasked} - Expira: ${
        expiresAt.getMonth() + 1
      }/${expiresAt.getFullYear()}`,
      type: `${certificate.issuerName} ${acAdditionalText}`,
      data: certificate,
      disabled: this.allowJustICP() && !isICP,
    };
  }

  private authenticate(
    certificate: any,
    certificateBase64: string | null,
    certificateAuth: ValidateCertificateAuthenticationOutput
  ): void {
    this._webPkiAdapter
      .signData({
        data: certificateAuth.nonce,
        thumbprint: certificate.data.thumbprint,
        digestAlgorithm: certificateAuth.digestAlgorithm,
      })
      .then((signature) => {
        this._router.navigate([`${this.cityIdentity}/callback`], {
          queryParams: {
            code: certificateAuth.nonce,
            state: 'ICP',
          },
          state: {
            signature,
            certificateBase64,
          },
        });
      })
      .catch((e) => {
        this._appHandler.setError(true, {
          message: 'A operação não pôde ser concluída.',
          description:
            'Não foi possível validar o certificado. Se o problema persistir, entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this.init();
          },
        });
        this._appHandler.setLoading(false);
      });
  }
}
