<div class="w-full h-screen flex">
  <div
    class="flex flex-col min-h-full w-full md:w-9/12 justify-center relative"
  >
    <div
      class="flex justify-center items-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <label
            for="cities"
            class="block mb-2 text-sm font-medium leading-6 text-gray-900"
          >
            Selecione a cidade para acessar
          </label>
          <select
            id="cities"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-aprova focus:border-aprova block w-full p-2.5"
            [formControl]="cityFormControl"
          >
            <option selected value="">Selecione a cidade</option>
            @for (city of listSorted; track city) {
            <option value="{{ city.identity }}">{{ city.name }}</option>
            }
          </select>
        </div>
      </div>
    </div>
    <div class="select-city-footer">
      <a href="https://aprova.com.br/" target="_blank">
        <span class="text-xs text-gray-600">Powered by</span>
        <!-- Voltar logo outubro rosa <img src="/assets/images/logo.svg" alt="aprova-logo" /> -->
        <img src="/assets/images/logo-rosa.svg" alt="aprova-logo" />

      </a>
    </div>
  </div>
  <div class="relative hidden w-full md:block bg-gray-200">
    <img
      class="absolute inset-0 h-full w-full object-cover"
      src="/assets/images/cover-login.jpg"
      alt="Cover"
    />
  </div>
</div>
