import { Component, ViewChild, inject, signal } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent } from '@aprova-digital/design-system';

import { AppHandler } from '../../app.handler';
import { InputComponent } from '../../components/input/input.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

import { Types } from '../../../domain/types.domain';
import { TwoFactorAuthenticationRepositoryInstruction } from '../../../builder/instructions/repositories/two-factor-authentication.repository.instructions';
import { TwoFactorAuthenticationServiceInstruction } from '../../../builder/instructions/services/two-factor-authentication.service.instruction';

import { SettingsInstruction } from '../../../builder/instructions/settings/settings.instruction';
import { TwoFactorAuthenticationContext } from '../../../domain/two-factor/enums/two-factor-authentication-context.enum';

@Component({
  selector: 'app-two-factor-authentication',
  standalone: true,
  imports: [
    AsyncPipe,
    NgOptimizedImage,
    InputComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
  ],
  providers: [
    new SettingsInstruction(),
    new TwoFactorAuthenticationRepositoryInstruction(),
    new TwoFactorAuthenticationServiceInstruction(),
  ],
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
})
export class TwoFactorAuthenticationComponent {
  @ViewChild('verificationCodeInput', { static: false })
  public verificationCodeInput: InputComponent | undefined;

  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);

  private readonly twoFactorAuthenticationService = inject(
    Types.TwoFactorAuthenticationService
  );

  public routeState = this._router.getCurrentNavigation()?.extras?.state;

  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public twoFactorAuthenticationName: string = '';

  public loading = signal(false);

  public verificationCodeFormControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  ngOnInit(): void {
    if (!this.routeState?.['verificationToken']) {
      this.redirectToLogin();
      return;
    }

    this._appHandler.setBackButtonHome(false);

    this._route.data.subscribe({
      next: ({ city }) => {
        this.cityIdentity = city.identidade;

        if (this.routeState) {
          this.twoFactorAuthenticationName =
            this.routeState['twoFactorAuthenticationName'];
        }

        this._appHandler.setLoading(false);
      },
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.verificationCodeInput?.inputRef?.nativeElement.focus();
    }, 500);
  }

  redirectToLogin(state?: { [key: string]: any }): void {
    this._router.navigate([`/${this.cityIdentity}`], {
      replaceUrl: true,
      state,
    });
  }

  confirm(): void {
    this._appHandler.setLoading(true, {
      title: 'Validando código de verificação',
      description: 'Este processo pode levar alguns segundos',
    });

    this.twoFactorAuthenticationService
      .handle({
        verificationCode: this.verificationCodeFormControl.value,
        token: this.routeState?.['verificationToken'],
        context: TwoFactorAuthenticationContext.AUTHENTICATION,
      })
      .then((response) => {
        this.loading.set(false);
        window.location.href = `${response.redirectUri}?code=${response.code}`;
      })
      .catch(() => {
        this._appHandler.setLoading(false);
        this._appHandler.setError(true, {
          message: 'A operação não pôde ser concluída.',
          description:
            'Se o problema persistir, entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this._appHandler.clearError();
            this.redirectToLogin();
          },
        });
      });
  }
}
