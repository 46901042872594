import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { Types } from '../../../domain/types.domain';
import { LoginByIntegrationOutput } from '../../../domain/auth/dtos/login-by-integration.output';

import { AppHandler } from '../../app.handler';
import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { UserRepositoryInstruction } from '../../../builder/instructions/repositories/user.repository.instructions';
import { LoginByIntegrationServiceInstruction } from '../../../builder/instructions/services/login-by-integration.service.instruction';
import { ResendAccountConfirmationServiceInstruction } from '../../../builder/instructions/services/resend-account-service.instruction';

import { User } from '../../../domain/user/entities/user.entity';

@Component({
  selector: 'app-login-integration',
  standalone: true,
  imports: [RouterOutlet],
  providers: [
    new AuthRepositoryInstruction(),
    new UserRepositoryInstruction(),
    new LoginByIntegrationServiceInstruction(),
    new ResendAccountConfirmationServiceInstruction(),
  ],
  templateUrl: './login-integration.component.html',
  styleUrls: ['./login-integration.component.scss'],
})
export class LoginIntegrationComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);

  private readonly _loginByIntegrationService = inject(
    Types.LoginByIntegrationService
  );
  private readonly _resendAccountConfirmationService = inject(
    Types.ResendAccountConfirmationService
  );

  private cityId: number = this._route.snapshot.data['city']['index'];
  public cityIndex: number = this._route.snapshot.data['city']['index'];
  public cityLogo: string | undefined = undefined;
  public cityName: string = '';
  public cityPhone: string = '';
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];
  public cityLoginUrl: string = '';

  public routeState = this._router.getCurrentNavigation()?.extras?.state;

  public requireSelectAccount = signal(false);
  public requireCompleteRegistration = signal(false);
  public accounts = signal<Array<User & { loginCode: string }>>([]);

  public redirectUri = signal('');

  ngOnInit(): void {
    this._appHandler.setBackButtonHome(false);

    this._route.data.subscribe({
      next: ({ city }) => {
        this.cityId = city.index;
        this.cityLogo = city.logo;
        this.cityIndex = city.index;
        this.cityIdentity = city.identidade;
        this.cityConnection = city.connection;
        this.cityName = city.title;
        this.cityPhone = city.tel;
        this.cityLoginUrl = city.loginUrl;

        const params = {
          ...this._route.snapshot.params,
          ...this._route.snapshot.queryParams,
          ...(this.routeState ?? {}),
        };

        this.callbackSubscribe(params);
      },
    });
  }

  redirectToLogin(): void {
    if (this.cityLoginUrl) {
      window.location.href = this.cityLoginUrl;
      return;
    }

    this._router.navigate([`/${this.cityIdentity}`]);
  }

  getUserAbreviation(user: User) {
    return user
      ? user.name[0] + user.name.trim().split(' ').slice(-1).pop()?.[0]
      : '';
  }

  selectAccountToLogin(account: User & { loginCode: string }): void {
    if (account.blocked) {
      return;
    }

    if (!account.emailVerified) {
      this._appHandler.setError(true, {
        message: 'A verificação da conta está pendente.',
        description:
          'Ação necessária para acessar o sistema. Clique no botão abaixo.',
        actionText: 'Reenviar e-mail de confirmação de conta',
        action: () => {
          this.resendAccountConfirmation(account.userId);
        },
      });

      return;
    }

    window.location.href = `${this.redirectUri()}?code=${account.loginCode}`;
  }

  callbackSubscribe(params: Record<string, any>) {
    const url = new URL(location.href);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.get('redirectTo')) {
      localStorage.setItem(
        'redirectTo',
        searchParams.get('redirectTo') as string
      );
    }

    this._loginByIntegrationService
      .handle({
        cityId: this.cityId,
        connection: this.cityConnection,
        code: params['code'],
        strategy: params['state'] || 'INTEGRATION',
        ...params,
      })
      .then((response: LoginByIntegrationOutput) => {
        this.redirectUri.set(response.redirectUri ?? '');

        if (response.requireSelectUser) {
          this._appHandler.setLoading(false);
          this.requireSelectAccount.set(true);
          this.accounts.set(response.accounts || []);
          return;
        }

        if (response.requireNewUser) {
          this._router.navigate([`/${this.cityIdentity}/create-account`], {
            queryParams: {
              skipAccountConfirmation: !response.options?.emailVerification,
            },
            state: {
              initialAccountData: response.userData,
              blockedFields: response.options?.blockFields,
            },
          });
          return;
        }

        if (response.requireCompleteRegistration) {
          this._router.navigate(
            [`/${this.cityIdentity}/complete-registration`],
            {
              state: {
                userData: response.userData,
                token: response.tempToken,
                redirectUri: `${response.redirectUri}?code=${response.code}`,
              },
            }
          );
          return;
        }

        window.location.href = `${response.redirectUri}?code=${response.code}`;
      })
      .catch((errorResponse: any) => {
        this._appHandler.clearError();
        this._appHandler.setLoading(false);

        if (errorResponse.error.code === 'emailNotVerified') {
          this._appHandler.setError(true, {
            message: 'A verificação da conta está pendente.',
            description:
              'Ação necessária para acessar o sistema. Clique no botão abaixo.',
            actionText: 'Reenviar e-mail de confirmação de conta',
            action: () => {
              this.resendAccountConfirmation(
                errorResponse.error.params?.userId
              );
            },
          });

          return;
        }

        if (errorResponse.error.code === 'userBlocked') {
          this._appHandler.setError(true, {
            message: 'Sua conta está bloqueada.',
            description:
              'Contate-nos através do chat, localizado no ícone no canto inferior direito, para prosseguir.',
          });

          return;
        }

        if (errorResponse.error.code === 'socialUserNotFound') {
          this._appHandler.setError(true, {
            message: 'Nenhum cadastro localizado',
            description:
              'Não foi encontrado um cadastro no nosso sistema com o email que você selecionou. Deseja realizar o seu cadastro?',
            actionText: 'Criar nova conta',
            action: () => {
              this._appHandler.clearError();
              void this._router.navigate(
                [`/${this.cityIdentity}/create-account`],
                {
                  queryParams: {
                    showWelcomeMessage: false,
                    skipAccountConfirmation:
                      errorResponse.error.params.emailVerified ?? false,
                    email: errorResponse.error.params.email,
                    name: errorResponse.error.params.name,
                  },
                  state: {
                    blockedFields: ['email', 'nome', 'sobrenome'],
                    verification: {
                      code: params['code'],
                      strategy: params['state'],
                      provider: params['provider'],
                    },
                  },
                }
              );
            },
          });

          return;
        }

        if (errorResponse.error.code === 'certificateExpired') {
          this._appHandler.setError(true, {
            message: 'Certificado expirado.',
            description: 'Não é possível realizar login com um certificado expirado.',
          });
          return;
        }

        this._appHandler.setError(true, {
          message: 'A operação não pôde ser concluída.',
          description:
            'Se o problema persistir, entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this._appHandler.clearError();
            this.redirectToLogin();
          },
        });
      });
  }

  finishLogin(): void {
    window.location.href = this.redirectUri();
  }

  private resendAccountConfirmation(userId: string): void {
    this._appHandler.setError(true, {
      message: 'Enviando e-mail de confirmação de conta...',
      description:
        'Este processo pode levar alguns segundos. Por favor, aguarde.',
    });

    this._resendAccountConfirmationService
      .handle({
        cityId: this.cityIndex,
        userId,
      })
      .then(() => {
        this._appHandler.setError(true, {
          message: 'E-mail enviado com sucesso!',
          description:
            'Verifique sua caixa de entrada e siga as instruções para confirmar sua conta.',
        });
      })
      .catch((errorResponse: any) => {
        if (errorResponse.error.code === 'recentVerificationRequest') {
          this._appHandler.setError(true, {
            message: 'Existe uma solicitação recente!',
            description:
              'Você já solicitou uma verificação de email recentemente. Aguarde algumas horas para solicitar novamente ou entre em contato com nossa equipe de suporte.',
          });
          return;
        }

        this._appHandler.setError(true, {
          message: 'Erro ao enviar o e-mail de confirmação de conta!',
          description:
            'Tente novamente ou entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this.resendAccountConfirmation(userId);
          },
        });
      });
  }
}
